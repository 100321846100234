import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainer } from "../components/micros"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Strona nie istnieje" />
    <SectionContainer>
      <h1>404: Strona nie istnieje</h1>
      <p>Sprawdź link, może to literówka 🤔</p>
    </SectionContainer>
  </Layout>
)

export default NotFoundPage
